// import { makePersisted } from '@solid-primitives/storage';
import { createStore } from 'solid-js/store';

export let isNestedPage = { value: false };

// TODO: use cookie instead
// const [session, setSession] = makePersisted(createSignal(), {
// 	storage: cookieStorage.withOptions({ expires: new Date(+new Date() + 3_600_000) }),
// });

const [memory, setMemory] = createStore({
	donations: [
		{
			id: '1',
			image:
				'https://cdn.worldcoo.com/campaigns/019c516a-f8a8-46e6-9eff-9181f302ac3e/media/56244d4a-05ab-4764-b170-e7557c3fc367.jpg',
			description: {
				title: 'Prevención de incendios forestales en Cataluña',
				merchant: 'Viena',
				date: '2024-07-20T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.35,
				},
			},
			status: 'pending' as const,
		},
		{
			id: '2',
			image:
				'https://cdn.worldcoo.com/campaigns/c36be4c7-d316-4533-973b-0cc5a6acbccc/media/cb103818-722d-41b0-b330-06c44855311b.jpg',
			description: {
				title: 'Educación para la infancia',
				merchant: 'Viena',
				date: '2024-07-15T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.2,
				},
			},
			status: 'done' as const,
		},
		{
			id: '3',
			image:
				'https://cdn.worldcoo.com/campaigns/019c516a-f8a8-46e6-9eff-9181f302ac3e/media/56244d4a-05ab-4764-b170-e7557c3fc367.jpg',
			description: {
				title: 'Prevención de incendios forestales en Cataluña',
				merchant: 'Viena',
				date: '2024-07-14T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.35,
				},
			},
			status: 'pending' as const,
		},
		{
			id: '4',
			image:
				'https://cdn.worldcoo.com/campaigns/c36be4c7-d316-4533-973b-0cc5a6acbccc/media/cb103818-722d-41b0-b330-06c44855311b.jpg',
			description: {
				title: 'Educación para la infancia',
				merchant: 'Viena',
				date: '2024-07-13T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.2,
				},
			},
			status: 'done' as const,
		},
		{
			id: '5',
			image:
				'https://cdn.worldcoo.com/campaigns/019c516a-f8a8-46e6-9eff-9181f302ac3e/media/56244d4a-05ab-4764-b170-e7557c3fc367.jpg',
			description: {
				title: 'Prevención de incendios forestales en Cataluña',
				merchant: 'Viena',
				date: '2024-07-12T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.35,
				},
			},
			status: 'pending' as const,
		},
		{
			id: '6',
			image:
				'https://cdn.worldcoo.com/campaigns/c36be4c7-d316-4533-973b-0cc5a6acbccc/media/cb103818-722d-41b0-b330-06c44855311b.jpg',
			description: {
				title: 'Educación para la infancia',
				merchant: 'Viena',
				date: '2024-07-11T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.2,
				},
			},
			status: 'done' as const,
		},
		{
			id: '7',
			image:
				'https://cdn.worldcoo.com/campaigns/019c516a-f8a8-46e6-9eff-9181f302ac3e/media/56244d4a-05ab-4764-b170-e7557c3fc367.jpg',
			description: {
				title: 'Prevención de incendios forestales en Cataluña',
				merchant: 'Viena',
				date: '2024-07-10T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.35,
				},
			},
			status: 'pending' as const,
		},
		{
			id: '8',
			image:
				'https://cdn.worldcoo.com/campaigns/c36be4c7-d316-4533-973b-0cc5a6acbccc/media/cb103818-722d-41b0-b330-06c44855311b.jpg',
			description: {
				title: 'Educación para la infancia',
				merchant: 'Viena',
				date: '2024-06-20T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.2,
				},
			},
			status: 'done' as const,
		},
		{
			id: '9',
			image:
				'https://cdn.worldcoo.com/campaigns/019c516a-f8a8-46e6-9eff-9181f302ac3e/media/56244d4a-05ab-4764-b170-e7557c3fc367.jpg',
			description: {
				title: 'Prevención de incendios forestales en Cataluña',
				merchant: 'Viena',
				date: '2024-06-15T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.35,
				},
			},
			status: 'pending' as const,
		},
		{
			id: '10',
			image:
				'https://cdn.worldcoo.com/campaigns/c36be4c7-d316-4533-973b-0cc5a6acbccc/media/cb103818-722d-41b0-b330-06c44855311b.jpg',
			description: {
				title: 'Educación para la infancia',
				merchant: 'Viena',
				date: '2024-06-14T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.2,
				},
			},
			status: 'done' as const,
		},
	],
});

function sliceArray<T>(batch: T[], itemPerBatch: number) {
	const slices: T[][] = [];
	for (let i = 0; i < batch.length; i += itemPerBatch) {
		slices.push(batch.slice(i, Math.min(i + itemPerBatch, batch.length)));
	}

	return slices;
}

export function getDonations(page: number) {
	// const pages = sliceArray(memory.donations, 6);
	// return { list: pages[page - 1] ?? [], totalPages: pages.length };
	return { list: memory.donations ?? [], totalPages: 1 };
}

export function certifyDonation(donationId: string) {
	setMemory('donations', (currentDonations) => [
		{
			id: donationId,
			image:
				'https://cdn.worldcoo.com/campaigns/a72f9e07-eeb6-4c74-ad66-81b5c96c5a6b/media/f924bb68-d39c-44ce-99f7-1d537d209c79.png',
			description: {
				title: '#ALIMENTANDOSOLIDARIDAD',
				merchant: 'Telefónica',
				date: '2024-08-10T12:00:00Z',
				transaction: {
					currency: 'EUR' as const,
					amount: 0.5,
				},
			},
			status: 'pending' as const,
		},
		...currentDonations,
	]);
}
