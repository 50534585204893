import {
	CognitoIdentityProviderClient,
	InitiateAuthCommand,
	SignUpCommand,
	ConfirmSignUpCommand,
	GetUserCommand,
} from '@aws-sdk/client-cognito-identity-provider';

let cognitoClient: CognitoIdentityProviderClient;
export const getCognitoClient = () => {
	if (!cognitoClient) cognitoClient = new CognitoIdentityProviderClient({ region: 'eu-west-1' });
	return cognitoClient;
};

export const signIn = async (username: string, password: string) => {
	const { AuthenticationResult } = await getCognitoClient().send(
		new InitiateAuthCommand({
			AuthFlow: 'USER_PASSWORD_AUTH',
			ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
			AuthParameters: {
				USERNAME: username,
				PASSWORD: password,
			},
		}),
	);
	if (AuthenticationResult) {
		sessionStorage.setItem('idToken', AuthenticationResult.IdToken || '');
		sessionStorage.setItem('accessToken', AuthenticationResult.AccessToken || '');
		sessionStorage.setItem('refreshToken', AuthenticationResult.RefreshToken || '');
		return AuthenticationResult;
	}
};

export const signUp = async (email: string, password: string) => {
	const response = await getCognitoClient().send(
		new SignUpCommand({
			ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
			Username: email,
			Password: password,
			UserAttributes: [
				{
					Name: 'email',
					Value: email,
				},
			],
		}),
	);
	console.log('Sign up success: ', response);
	return response;
};

export const confirmSignUp = async (username: string, code: string) => {
	await getCognitoClient().send(
		new ConfirmSignUpCommand({
			ClientId: import.meta.env.VITE_COGNITO_CLIENT_ID,
			Username: username,
			ConfirmationCode: code,
		}),
	);
	console.log('User confirmed successfully');
	return true;
};

export const getUserAttributes = async () => {
	return getCognitoClient().send(
		new GetUserCommand({
			AccessToken: sessionStorage.getItem('accessToken')!,
		}),
	);
};
